.App {
  height: 100vh;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
          30deg,
          hsl(240deg 100% 20%) 0%,
          hsl(319deg 100% 30%) 41%,
          hsl(346deg 83% 51%) 70%,
          hsl(30deg 100% 55%) 91%,
          hsl(55deg 100% 50%) 100%
  );
}