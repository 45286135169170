.card{
    /*height: 100%;*/
    /*width: 40vmin;*/
     width: min-content;
    height: min-content;
    background-color: rgb(217, 217, 217, 0.8);
}

.profile{
    padding-left: 4vw;
    padding-right: 4vw;
    position: relative;
    border-radius: 50%;
    margin-top: min(max(1vh, 2vw), 5vh);
    margin-left: auto;
    margin-right: auto;
}

.avatar{
    background-image: url("../../../static/usericon.png");
    background-size: cover;
    height: 22vmin;
    width: 22vmin;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    background-color: rgb(217, 217, 217, 1);
}


.profile_icon{
    height: 50%;
    width: 50%;
}

.player_rating_container{
    position: absolute;
    bottom: 0;
    right: 10%;
    display: flex;
    height: 20%;
    width: 30%;
    background-color: rgb(217, 217, 217, 1);
    border:  1px solid black;
    border-radius: 15px;
    z-index: 2;
}

.rating{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    font-size: min(max(1.5vh, 2vw), 3vh);
}

.name_player_container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(max(1vh, 2vw), 3vh);
    height: 10%;
    /*margin-top: 5vh;*/
    /* margin-top: min(max(3vh, 2vw), 5vh);*/
    margin-top: min(max(1vh, 2vw), 5vh);
    width: 100%;
}

.change_rating{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(max(2vh, 4vw), 7vh);
    height: 10%;
    width: 100%;
    color: white;
    /*margin-top: 3vh;*/
    /*margin-top: min(max(3vh, 2vw), 5vh);*/
    margin-top: min(max(1vh, 2vw), 5vh);
}


/*@media (max-height: 499px) and (max-width: 930px) {*/
/*    .avatar{*/
/*        height: 22vmin;*/
/*        width: 2vmin;*/
/*    }*/
/*    .name_player_container{*/
/*        color: #1A01CC;*/
/*    }*/
/*}*/

@media (max-width: 1100px) {
    .card{
        width: 45vmin;
    }
}

@media (max-width: 650px) {
    .avatar{
        height: 19vmin;
        width: 19vmin;
    }
}

@media (max-width: 450px) {
    .card{
        height: 25vh;
        margin-top: auto;
        margin-bottom: auto;
    }

    .avatar{
        height: 22vmin;
        width: 22vmin;
    }

    .name_player_container{
        font-size: 5vw;
    }

    .change_rating{
        font-size: 7vw;
    }
}

@media (max-width: 450px) {
    .name_player_container{
        font-size: 4vw;
    }

    .change_rating{
        font-size: 6vw;
    }

    .avatar{
         height: 18vmin;
         width: 18vmin;
    }

    .card{
        width: 35vw;
    }
}

@media (max-width: 350px) {
    .name_player_container{
        font-size: 6vw;
    }

    .change_rating{
        font-size: 8vw;
    }

    .avatar{
         height: 22vmin;
         width: 22vmin;
    }

    .card{
        width: 35vw;
    }
}

@media (max-height: 300px) {
    .card{
        width: 20vw;
    }

    .player_rating_container{
        right: 25%;
        height: 25%;
        width: 20%;
    }

    .avatar{
        height: 45vmin;
        width: 45vmin;
    }
}

@media (max-height: 200px) {
    .player_rating_container{
        right: 30%;
        height: 25%;
        width: 15%;
    }

    .name_player_container{
        font-size: 12vh;
    }

    .change_rating{
        font-size: 15vh;
    }
    .card{
        width: 15vw;
    }
}

@media (max-height: 499px) and (max-width: 930px) {
    .avatar{
        height: 23vmin;
        width: 23vmin;
    }
    .name_player_container{
        font-size: 3vw;
    }

    .change_rating{
        font-size: 5vw;
    }
}

@media (max-height: 499px) and (max-width: 560px) {
    .avatar{
        height: 16vmin;
        width: 16vmin;
    }
    .name_player_container{
        font-size: 3vw;
    }

    .change_rating{
        font-size: 5vw;
    }
}

@media (max-height: 360px) and (max-width: 450px) {
    .avatar{
        height: 10vmin;
        width: 10vmin;
    }
    .name_player_container{
        font-size: 3vw;
    }

    .change_rating{
        font-size: 5vw;
    }
}
