.logout_panel {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.logout_btn {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    background-color: #D9D9D9;
}

@media (max-width: 750px) {
    .logout_panel {
        min-width: 18rem;
        max-width: 65%;
    }
}

@media (max-width: 300px) {
    .logout_panel {
        width: 300px;
        max-width: 300px;
        min-width: 250px;
    }
}