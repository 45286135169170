.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    margin: 10%;
    min-width: 300px;
    max-width: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modalBody {
    padding-top: 20px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputField {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.submitButton:hover {
    background-color: #0056b3;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.closeButton:hover {
    color: red;
}

.errorMessage {
    color: red;
    font-weight: bold;
    font-size: 14px;
    margin-top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}