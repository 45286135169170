.profile_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 35rem;
    background-color: rgba(217, 217, 217, 0.5);
    width: 40%;
    max-width: 40%;
    min-width: 18rem;
    border-radius: 0.5rem;
}

.profile_photo {
    background-color: #D9D9D9;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0.5rem;
}

.photo_img {
    width: 100%;
    height: auto;
}

.profile_username{
    font-size: 2rem;
    margin-bottom: 1rem;
}

.profile_achievements{
    width: 50%;
    margin-bottom: 2rem;
}

.profile_field
{
    display: flex;
    justify-content: space-between;
}

.profile_field_name,
.profile_field_value{
    font-size: 1.5rem;
}

/*.add_friend_btn{*/
/*    background-color: #D9D9D9;*/
/*    padding: 0.4rem 1.2rem;*/
/*    border-radius: 1.3rem;*/
/*    font-size: 1.5rem;*/
/*}*/

@media (max-width: 750px) {
    .profile_card{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 80%;
        min-width: 18rem;
        margin-bottom: 2rem;
    }
    .profile_achievements{
        width: 60%;
        margin-bottom: 2rem;
    }
}
