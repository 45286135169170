.profile_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: auto;
}

.player_overview{
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 20rem;
}

.player_content{
    height: 35rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 750px) {
    .player_overview{
        flex-direction: column;
        align-items: center;
        height: 200rem;
    }

    .player_content{
        height: 32rem;
        width: 100%;
    }
}

@media (max-width: 250px) {
    .player_overview{
        align-items: start;
        width: 100%;
    }
}