.player_dashboard_card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 1rem;
    width: 80%;
    margin-bottom: 2rem;
    padding: 3rem 0;
}

/*.photo_management {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.profile_photo {*/
/*    background-color: #D9D9D9;*/
/*    width: 12rem;*/
/*    height: 12rem;*/
/*    border-radius: 50%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    overflow: hidden;*/
/*}*/

/*.photo_img {*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

/*.profile_management {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*}*/

.game_actions{
    align-items: center;
}

/*.profile_field {*/
/*    padding: 0.3rem;*/
/*    font-size: 2rem;*/
/*    border-radius: 1rem;*/
/*    margin-bottom: 2rem;*/
/*    box-sizing: border-box;*/
/*    word-wrap: break-word;*/
/*    white-space: nowrap;*/
/*}*/

.personal_information_container{
    width: 90%;
}

/*@media (max-width: 800px) {*/
/*    .profile_photo {*/
/*        width: 9rem;*/
/*        height: 9rem;*/
/*    }*/
/*    .profile_field*/
/*    {*/
/*        padding: 0.3rem;*/
/*        font-size: 1.3rem;*/
/*        border-radius: 1rem;*/
/*        margin-bottom: 2rem;*/
/*    }*/

/*}*/

@media (max-width: 550px) {
    .player_dashboard_card{
        width: 80%;
        max-width: 80%;
        min-width: 15rem;
        height: 25rem;
        flex-direction: column;
    }

    /*.profile_photo {*/
    /*    width: 9rem;*/
    /*    height: 9rem;*/
    /*}*/

    /*.profile_management{*/
    /*    align-items: center;*/
    /*}*/
}

@media (max-width: 250px) {
    .player_dashboard_card
    {
        align-items: start;
        width: 100%;
    }
}
