.outer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
}

.main_container{
    background-color: rgb(217, 217, 217, 0.5);
    width: 27rem;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.main_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-bottom: 0.7rem;
}

.container_redirect{
    color: rgb(218, 192, 211);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    font-size: 1rem;
}

.container_redirect span{
    margin-right: 10px;
}


.container_error{
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 0.7rem;
    font-weight: bold;
    color: #DAC0D3;
    margin-top: 1vh;
}

.message_box {
    background-color: rgb(217, 217, 217, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 90%;
    text-align: center;
}

.success_message {
    margin-bottom: 20px;
}

.success_title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.success_text {
    color: #555;
    margin-bottom: 20px;
}

/* Стиль для кнопки */
.login_button {
    margin-top: 20px;
}

.login_button a {
    display: inline-block;
    padding: 12px 25px;
    background-color: rgba(217, 12, 89, 0.5);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

.login_button a:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.error_message {
    color: red;
    margin-top: 15px;
}

@media (max-width: 300px) {
    .header{
        font-size: 1.5rem;
    }
}

@media (max-height: 300px) {
    .outer_container {
        align-items: flex-start;
    }
}