.game_statistic_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*height: 15rem;*/
    max-height: 15rem;
    background-color: rgba(217, 217, 217, 0.5);
    width: 80%;
    max-width: 80%;
    min-width: 17rem;
    border-radius: 0.5rem;
    overflow-y: auto;
}


.game_statistics_table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
}

.game_statistics_table th,
.game_statistics_table td {
    padding: 0.8rem;
    text-align: left;
    border-bottom: 1px solid #ccc;
}


.game_statistics_table th {
    font-weight: bold;
}

.game_statistics_table tr:hover {
    background-color: rgba(200, 200, 200, 0.3);
}

.game_statistic_card::-webkit-scrollbar
{
    width: 0;
    height: 0;
}

.agree_invitation_btn,
.disagree_invitation_btn
{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    padding: 0.3rem 0.8rem;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.agree_invitation_btn
{
    background-color: green;
}

.disagree_invitation_btn
{
    background-color: red;
}

.agree_invitation_btn:hover {
    background-color: #19d304;
    transform: scale(1.05);
}

.disagree_invitation_btn:hover {
    background-color: #b40000;
    transform: scale(1.05);
}

@media (max-width: 750px) {
    .game_statistic_card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
        max-width: 80%;
        min-width: 18rem;
        height: 15rem;
        overflow-y: auto;
    }
}