.game_actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.game_action_btn {
    display: flex;
    justify-content: center;
    width: 15rem;
    padding: 0.3rem;
    font-size: 2rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
    background-color: white;
}

@media (max-width: 800px) {
    .game_action_btn {
        padding: 0.3rem;
        font-size: 1.3rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }

    .game_action_btn{
        width: 10rem;
        display: flex;
        background-color: white;
        justify-content: center;
    }
}