.main_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: auto;
}

.statistics{
    display: flex;
    width: 80%;
    justify-content: space-between;
}

@media (max-height: 700px) {
    .main_page{
        display: flex;
        justify-content: start;
    }
}

@media (max-width: 800px) {
    .statistics{
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 200px) {
    .main_page,
    .statistics
    {
        align-items: start;
        width: 100%;
    }
}
