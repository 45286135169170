.friends_statistics{
    display: flex;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 1rem;
    width: 60%;
    overflow: auto;
    min-height: 100px;
    max-height: 17rem;
    /*max-height: 10rem;*/
}

.leaders_statistics{
    display: flex;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 1rem;
    width: 30%;
    overflow: auto;
    min-height: 100px;
    max-height: 15rem;
}

.game_statistics_table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
}

.game_statistics_table th{
    padding: 0.8rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

.game_statistics_table td {
    padding: 0.8rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 1.5em
}

.game_statistics_table tr:hover {
    background-color: rgba(200, 200, 200, 0.3);
}

.play_btn{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    padding: 0.3rem 0.8rem;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.play_btn:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
}

@media (max-width: 800px) {
    .friends_statistics{
        width: 100%;
        margin-bottom: 2rem;
    }

    .leaders_statistics{
        width: 100%;
    }
}

@media (max-width: 550px) {
    .friends_statistics
    {
        max-width: 100%;
        min-width: 15rem;
    }

    .leaders_statistics
    {
        max-width: 100%;
        min-width: 15rem;
    }

}

.friends_statistics::-webkit-scrollbar,
.leaders_statistics::-webkit-scrollbar
{
    width: 0;
    height: 0;
}