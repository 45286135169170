.photo_management {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.profile_photo {
    background-color: #D9D9D9;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.photo_img {
    width: 100%;
    height: auto;
}

.profile_management {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile_field {
    padding: 0.3rem;
    font-size: 2rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .profile_photo {
        width: 9rem;
        height: 9rem;
    }
    .profile_field
    {
        padding: 0.3rem;
        font-size: 1.3rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }

}

@media (max-width: 550px) {
    .profile_photo {
        width: 9rem;
        height: 9rem;
    }

    .profile_management{
        align-items: center;
    }
}