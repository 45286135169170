.main_context{
    display: flex;
    width: 80%;
    background-color: rgb(217, 217, 217, 1);
    border-radius: 1vh;
}

.input{
    padding: 0.3rem 0.3rem 0.3rem 1.5vw;
    font-size: 1.2rem;
    width: 80%;
    box-sizing: border-box;
    background-color: rgb(217, 217, 217, 1);
    color: #000000;
    border-radius: 1vh;
    border: none;
    outline: none;

}

@media (max-width: 300px) {
    .input {
        font-size: 0.6rem;
    }
}

.input::placeholder {
    font-weight: bold;
    opacity: 1;
    color: #6d6262;
    border: none;
}

.container_input{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding-bottom: 1rem;
}

.eye{
    margin: auto;
}