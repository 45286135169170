.main_container{
    display: flex;
    width: 90vw;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
}

.game_panel{
    height: 500px;
    min-width: 500px;
    margin-right: 5%;
}

.player_info_panel{
    display: flex;
    height: 10%;
    width: 100%;
}

.player_photo_container{
    height: 100%;
    width: 50px;
    background-color: black;
    margin-right: 1vw;
}

.player_figures{
    height: 100%;
    width: 50%;
}

.player_name{
    height: 50%;
    width: 100%;
}

.taken_figures{
    height: 50%;
    width: 100%;
}

.timer{

}

.board_container{
    background-color: wheat;
    height: 80%;
    width: 100%;
}

.interaction_panel{
    background-color: wheat;
    height: 100%;
    width: 40%;
}

.report_container{

}

.player_decision_options{

}

.chat_container{

}

.btn_logout_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 5vw;
    width: 12%;
    height: 6vh;
    background-color: #af2665;
    opacity: 1;
    border-radius: 15px;
    margin-top: 5vh;
}

.result{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60vh;
    width: 80%;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}

.VS_div{
    width: min-content;
    height: min-content;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    align-items: center;
    font-size: min(max(2vh, 10vw), 65vh);
    color: white;
}

.btn_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    height: 7vh;
    background-color: #af2665;
    opacity: 0.6;
    border-radius: 15px;
    margin-top: 10vh;
}

.btn_text{
    text-align: center;
    /*font-size: 20px;*/
    font-size: min(max(1.5vh, 2vw), 3vh);
    padding-bottom: 4px;

}


@media (max-width: 450px) {
    .result{
        /*height: 80vh;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin-top: 10vh;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-height: 500px) {
    .result{
        display: flex;
        justify-content: space-around;
        width: 60%;
    }

    .btn_logout_container{
        width: 8%;
    }
}

@media (max-height: 550px) {
    .btn_container{
        width: 11%;
    }
}

@media (max-height: 550px) and (max-width: 440px) {
    .result{
        display: flex;
        flex-direction: row;
    }
}

@media (max-height: 320px)  {
    .btn_logout_container{
        width: 5%;
    }
}

@media (max-height: 360px) {
    .btn_container{
        width: 7%;
    }
}

@media (max-width: 700px) {
    .btn_logout_container{
        height: 4vh;
    }
    .btn_container{
        height: 5vh;
    }
}

@media (max-width: 400px) {
    .btn_text{
        font-size: min(max(0.5vh, 2vw), 3vh);
    }
}
