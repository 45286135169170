.invite_field{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    background-color: #f9f9f9;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
}

.inviter_field{
    font-size: 1.2rem;
}

.invite_decision_btn{
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem    ;
    cursor: pointer;
}